import axios from 'axios';

import api, { publicApi } from './api';

export const getConfigurations = (iclinic_physician_id) =>
  api.get(`/schedule/${iclinic_physician_id}/`);

export const getDoctors = (
  autocomplete = '',
  insurance = '',
  city = '',
  accepts_telemedicine = '',
  page = 1,
) => {
  const params = new URLSearchParams();
  params.append('autocomplete', autocomplete);
  params.append('insurance', insurance);
  params.append('city', city);
  params.append(
    'consultation_type',
    accepts_telemedicine === 'true' ? 'telemedicine' : 'in_person',
  );
  params.append('page', page);
  return api.get('/v2/search/profiles/', { params });
};

export const getUserLocation = (longitude, latitude) =>
  axios.get(process.env.GOOGLE_GEOCODING_URL, {
    params: {
      key: process.env.GOOGLE_GEOCODING_KEY,
      latlng: `${longitude},${latitude}`,
      sensor: false,
    },
  });

export const getPageDoctors = (link) => api.get(link);

export const getLinks = () => publicApi.get('/v2/search/most-popular');

export default {
  getDoctors,
};
