// Internal
import { appTypes } from 'app/shared/utils/actions';

const search = appTypes.defineAction('search');

// eslint-disable-next-line import/prefer-default-export
export const SEARCH = search.defineAction('SEARCH',
  [
    'IS_FETCHING',
    'DATA_SEARCH',
    'FETCH_EVENTS',
    'USER_LOCATION',
    'USER_LOCATION_SUCCESS',
    'USER_LOCATION_FAILURE',
    'FETCH_PAGE_RESULT',
    'FETCH_EVENTS_SUCCESS',
    'FETCH_EVENTS_FAILURE',
    'FETCH_PAGE_RESULT_SUCCESS',
    'FETCH_PAGE_RESULT_FAILURE',
  ],
);
