export default [
  'Affego',
  'Agemed',
  'Allianz',
  'Amagis',
  'Amil',
  'Ammp',
  'Apas',
  'Assefaz',
  'Assim',
  'Bradesco',
  'Cabergs',
  'Cabesp',
  'Cac',
  'Cafaz',
  'Caixa',
  'Camed',
  'Capesesp',
  'Care Plus',
  'Casembrapa',
  'Cassems',
  'Cassi',
  'Cemig',
  'Cesp',
  'Copass',
  'Copel',
  'Correios',
  'Dix',
  'Doctor Clin',
  'E-Vida',
  'Economus',
  'Fachesf',
  'Fundaffemg',
  'Fusex',
  'GAMA',
  'Geap',
  'Golden Cross',
  'Hapvida',
  'Infraero',
  'Intermédica',
  'Ipe',
  'Ipergs',
  'Itaú',
  'Marítima',
  'Mediservice',
  'Notre Dame',
  'Odontoprev',
  'Omint',
  'Pasa',
  'Petrobrás',
  'Porto Seguro',
  'Postal',
  'Premium',
  'Prevent Senior',
  'Proasa',
  'Promed',
  'Real Grandeza',
  'Rn Saúde',
  'Sabesprev',
  'Samp',
  'Sanepar',
  'Santa Casa',
  'São Francisco',
  'Saúde PAS',
  'Serpro',
  'Sul América',
  'Sus',
  'Tacchimed',
  'Telos',
  'Unafisco',
  'Unimed',
  'Usisaúde',
  'Vale',
  'Vitallis',
];
