import api, { publicApi } from './api';

type InterceptorResponse<ResponseData> = {
  getResponseData: () => ResponseData;
};

type AcceptTerms = {
  status: true;
};

export type ServiceTerms = {
  accepted: boolean;
  identifier: string;
  version: string;
};

export type AcceptTermsResponse = InterceptorResponse<AcceptTerms>;

export type ProfilePayload = {
  treatment: string;
  first_name: string;
  last_name: string;
  cbo: number;
  email: string;
  password: string;
  is_accept_policy: boolean;
  is_accept_terms: boolean;
  cbo_id?: number;
  council_name?: number;
  id?: number;
  register_number?: string;
  rqe?: string;
};

// endpoints
export const update = (id: string, payload: ProfilePayload) =>
  api.patch('/v2/profiles/me/', payload);
export const getCurrentUser = () => api.get('/v2/profiles/me/');
export const signUp = (payload: ProfilePayload) =>
  api.post('/signup/', payload);

// public
export const getBySlug = (slug: string) => publicApi.get(`/profile/${slug}/`);
export const getSpecialties = () => publicApi.get('/v2/specialties');
export const getPartner = (id: string) => publicApi.get(`/partner/${id}/`);

export const acceptTerms = (profile: Number, payload: ServiceTerms) =>
  api.post<AcceptTermsResponse>(
    `/profiles/${profile}/accept-service-term/`,
    payload,
  );

export default {
  update,
};
