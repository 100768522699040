/* eslint-disable react/prop-types */
import React from 'react';
import RowBoot from 'react-bootstrap/Row';
import ColBoot from 'react-bootstrap/Col';
import ContainerBoot from 'react-bootstrap/Container';

import { CITY, INSURANCE } from 'shared/constants/queries';
import { formatToFriendlyUrl } from 'shared/utils/seo';

import css from './Links.module.scss';

const generateSpecialtyLink = (item) => {
  return `/${formatToFriendlyUrl(item.term)}`;
};

const Links = ({ generateLink, information }) => {
  const { specialties, cities, insurances } = information;

  return (
    <RowBoot className={css['links-button']}>
      <ContainerBoot>
        <ColBoot>
          <RowBoot className={css.line}>
            <ColBoot className={css.section} lg={4} md={12} sm={12}>
              <h2>Especialidades médicas</h2>
              {specialties.length &&
                specialties.map((item) => (
                  <a
                    className={css['button-link']}
                    href={generateSpecialtyLink(item)}
                    key={item.id}
                  >
                    {item.term}
                  </a>
                ))}
            </ColBoot>
            <ColBoot className={css.section} lg={3} md={12} sm={12}>
              <h2>Cidades</h2>
              {cities.length &&
                cities.map((item) => (
                  <a
                    className={css['button-link']}
                    href={generateLink(CITY, item.term)}
                    key={item.term}
                  >
                    {item.term}
                  </a>
                ))}
            </ColBoot>
            <ColBoot className={css.section} lg={2} md={12} sm={12}>
              <h2>Convênios</h2>
              {insurances.length &&
                insurances.map((item) => (
                  <a
                    className={css['button-link']}
                    href={generateLink(INSURANCE, item.term)}
                    key={item.term}
                  >
                    {item.term}
                  </a>
                ))}
            </ColBoot>
            <ColBoot className={css.section} lg={3} md={12} sm={12}>
              <h2>Parceiros</h2>
              <a
                className={css['button-link']}
                href="https://cliquefarma.com.br/"
              >
                Compare preços de remédios
              </a>
            </ColBoot>
          </RowBoot>
        </ColBoot>
      </ContainerBoot>
    </RowBoot>
  );
};

export default Links;
