const formatToFriendlyUrl = (url) => {
  const illegalCharacters = /[\u0300-\u036f]/g;
  const whitespace = /\s+/g;
  const notLetterOrNumber = /[^a-z0-9-]/g;
  const startingDashes = /^-*/;
  const dashes = /-+/g;
  const finalDashes = /-*$/;
  const ampersand = /&/g;

  return url
    .toString()
    .normalize('NFD')
    .replace(illegalCharacters, '')
    .replace(whitespace, '-')
    .toLowerCase() // Change to lowercase
    .replace(ampersand, '-e-') // Replace ampersand
    .replace(notLetterOrNumber, '')
    .replace(dashes, '-')
    .replace(startingDashes, '')
    .replace(finalDashes, '');
};

module.exports = {
  formatToFriendlyUrl,
};
