export default {
  specialties: [
    { term: 'Médico cardiologista' },
    { term: 'Médico pediatra' },
    { term: 'Médico ginecologista e obstetra' },
    { term: 'Médico dermatologista' },
    { term: 'Médico psiquiatra' },
    { term: 'Médico oftalmologista' },
    { term: 'Médico ortopedista e traumatologista' },
    { term: 'Médico clínico' },
    { term: 'Médico endocrinologista e metabologista' },
    { term: 'Médico neurologista' },
  ],
  cities: [
    { term: 'São Paulo - SP' },
    { term: 'Rio de Janeiro - RJ' },
    { term: 'Ribeirão Preto - SP' },
    { term: 'Belo Horizonte - MG' },
    { term: 'Curitiba - PR' },
    { term: 'Porto Alegre - RS' },
    { term: 'Goiânia - GO' },
    { term: 'Brasília - GO' },
    { term: 'Fortaleza - CE' },
    { term: 'Campo Grande - MS' },
  ],
  insurances: [
    { term: 'Particular' },
    { term: 'Unimed' },
    { term: 'Bradesco' },
    { term: 'Amil' },
    { term: 'Cassi' },
    { term: 'Saúde Caixa' },
    { term: 'GEAP' },
    { term: 'SulAmérica' },
    { term: 'Mediservice' },
    { term: 'Omint' },
  ],
};
