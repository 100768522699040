export default {
  fill: '/static/images/fill.png',
  search: '/static/images/search.png',
  location: '/static/images/location.png',
  arrowLeft: '/static/images/arrow-left.png',
  arrowRight: '/static/images/arrow-right.png',
  scheduleTelemedicine: '/static/images/schedule-telemedicine.png',
  widgets: '/static/images/widgets.png',
  messageOk: '/static/images/message-ok.png',
  help: '/static/images/help.png',
};
