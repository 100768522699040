import React, { useState } from 'react';

import { Modal, Flex, Button, Text, H2 } from 'app/components';
import imagePaths from 'shared/constants/imagePaths';

import css from './TelemedicineModal.module.scss';
import constants from './TelemedicineModal.constants';

type TelemedicineModalProps = {
  children: React.ReactNode;
};

export default function TelemedicineModal({
  children,
}: TelemedicineModalProps): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={css['telemedicine-modal']}>
      <div
        className={css['telemedicine-modal-trigger']}
        role="button"
        tabIndex={-1}
        onKeyDown={() => setModalOpen(true)}
        onClick={() => setModalOpen(true)}
      >
        {children}
      </div>
      <Modal isVisible={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Title>
          <H2 kind="sm" align="center">
            {constants.howItWorks}
          </H2>
        </Modal.Title>
        <Modal.Body>
          <div className={css['telemedicine-modal-body']}>
            <Flex direction="col">
              <div className={css['telemedicine-modal-image']}>
                <img
                  className={css.logo}
                  alt="Logo"
                  src={imagePaths.scheduleTelemedicine}
                />
              </div>
              <H2 kind="heading" align="center" margin="m-y-sm">
                {constants.whatIsTelemedicine}
              </H2>
              <Text
                kind="subdued"
                align="center"
                margin="m-bottom-lg"
                innerHTML={constants.whatIsTelemedicineText}
              />

              <div className={css['telemedicine-modal-image']}>
                <img className={css.logo} alt="Logo" src={imagePaths.widgets} />
              </div>
              <H2 kind="heading" align="center" margin="m-y-sm">
                {constants.howToPrepareMySelf}
              </H2>
              <Text
                kind="subdued"
                align="center"
                margin="m-bottom-sm"
                innerHTML={constants.howToPrepareMySelfText}
              />
              <ul>
                {constants.howToPrepareMySelfTextItems.map((item) => (
                  <li key={item.replace(' ', '-')}>
                    <Text kind="subdued">{item}</Text>
                  </li>
                ))}
              </ul>

              <div className={css['telemedicine-modal-image']}>
                <img
                  className={css.logo}
                  alt="Logo"
                  src={imagePaths.messageOk}
                />
              </div>
              <H2 kind="heading" align="center" margin="m-y-sm">
                {constants.howToAccess}
              </H2>
              <Text
                kind="subdued"
                align="center"
                margin="m-bottom-lg"
                innerHTML={constants.howToAccessText}
              />
            </Flex>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Flex justify="justify-end">
            <Button onClick={() => setModalOpen(false)}>Fechar</Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
