// Internal
import { SEARCH } from './types';

export const fetchResultSearch = ({
  autocomplete,
  insurance,
  city,
  accepts_in_person,
  accepts_telemedicine,
  page,
}) => ({
  type: SEARCH.FETCH_EVENTS,
  autocomplete,
  insurance,
  city,
  accepts_in_person,
  accepts_telemedicine,
  page,
});

export const fetchUserLocation = (location) => ({
  type: SEARCH.USER_LOCATION,
  location,
});

export const fetchUserLocationSuccess = (payload) => ({
  type: SEARCH.USER_LOCATION_SUCCESS,
  payload,
});

export const fetchUserLocationFailure = (payload) => ({
  type: SEARCH.USER_LOCATION_FAILURE,
  payload,
});

export const fetchPageResult = (link) => ({
  type: SEARCH.FETCH_PAGE_RESULT,
  link,
});

export const setDataSearch = (payload) => ({
  type: SEARCH.DATA_SEARCH,
  payload,
});

export const fetchResultSearchSuccess = (payload) => ({
  type: SEARCH.FETCH_EVENTS_SUCCESS,
  payload,
});

export const fetchPageResultSuccess = (payload) => ({
  type: SEARCH.FETCH_PAGE_RESULT_SUCCESS,
  payload,
});

export const fetchResultSearchFailure = (message) => ({
  type: SEARCH.FETCH_EVENTS_FAILURE,
  message,
});

export const fetchPageResultFailure = (message) => ({
  type: SEARCH.FETCH_PAGE_RESULT_FAILURE,
  message,
});
