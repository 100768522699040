export default {
  howItWorks: 'Como funciona a teleconsulta?',
  whatIsTelemedicine: 'O que é a Teleconsulta?',
  whatIsTelemedicineText:
    'A Teleconsulta é uma das modalidades da Telemedicina, definida como <strong>consulta médica realizada a distância</strong> por meio de videoconferências.<br>Você pode receber um atendimento de qualquer lugar, com total segurança e sigilo, além de contar com o envio de documentos eletrônicos, como receitas, solicitações de exames, entre outros.',
  howToPrepareMySelf: 'Como me preparar para uma Teleconsulta?',
  howToPrepareMySelfText:
    'Para que você tenha a melhor experiência possível no seu atendimento, é importante estar em um local silencioso, com privacidade, bem iluminado e com internet estável.<br>Assim, você garante que ninguém irá escutar informações sensíveis sobre sua saúde, e melhora a qualidade da sua videoconferência.<br>É importante lembrar que:',
  howToPrepareMySelfTextItems: [
    'O profissional de saúde irá conversar com você por meio de áudio, vídeo e chat. Alguns exames poderão ser realizados de acordo com as necessidades e limitações físicas;',
    'Atendimentos por Teleconsulta estão autorizados durante a pandemia pelo Ministério da Saúde, e eles possuem o mesmo valor de uma consulta presencial;',
    'A Teleconsulta é cobrada assim como a consulta presencial, conforme combinado entre você e o seu profissional de saúde;',
    'Verifique se você está em um local com bom sinal de internet, de preferência próximo ao roteador, se for usar rede Wi-Fi;',
    'Lembre-se de ficar de frente para a luz, e nunca contra ela, para melhorar a qualidade da sua imagem;',
    'Evite se deslocar durante a consulta médica.',
  ],
  howToAccess: 'Como acessar sua Teleconsulta?',
  howToAccessText:
    'Alguns minutos antes do horário agendado nós enviaremos via WhatsApp (se disponível), SMS e e-mail, o <strong>link</strong> e o <strong>código de acesso</strong> para sua Teleconsulta, que pode ser acessada por meio de qualquer dispositivo eletrônico conectado à internet.<br>Para entrar na sala, você deverá <strong>concordar</strong> com os <strong>termos de uso</strong> do Teleatendimento.',
};
