import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';
import Router from 'next/router';
import {
  Row as RowBoot,
  Col as ColBoot,
  Form as FormBoot,
  ButtonGroup as ButtonGroupBoot,
  ToggleButton as ToggleButtonBoot,
  ListGroup as ListGroupBoot,
} from 'react-bootstrap';

import Autocomplete from 'react-autocomplete';

import titleSearch from 'shared/constants/titleSearch';
import imagePaths from 'shared/constants/imagePaths';
import TelemedicineModal from './TelemedicineModal';

import css from './Search.module.scss';
import { getSpecialties } from '../../../services/api/profile';
import insurances from '../../../shared/utils/insurances';

type SearchProps = {
  userLocation?: () => void;
  searching: boolean;
  autocompleteValue?: string;
  cityValue?: string;
  describe: string;
  insuranceValue?: string;
  acceptsInPersonValue?: boolean;
  acceptsTelemedicineValue?: boolean;
};

type Specialty = {
  code: string;
  id: number;
  term: string;
};

const shouldItemRenderSpecialties = (item: Specialty, value: string) => {
  const { term } = item;
  return term.toLowerCase().indexOf(value.toLowerCase()) > -1;
};

const shouldItemRenderInsurances = (item: string, value: string) => {
  return item.toLowerCase().indexOf(value.toLowerCase()) > -1;
};

const cx = classNames.bind(css);

const Search = ({
  userLocation,
  autocompleteValue,
  searching,
  insuranceValue,
  describe,
  cityValue,
  acceptsInPersonValue,
  acceptsTelemedicineValue,
}: SearchProps) => {
  const [specialties, setSpecialties] = useState([]);
  const [specialty, setSpecialty] = useState(autocompleteValue);
  const [insurance, setInsurance] = useState(insuranceValue);

  useEffect(() => {
    (async () => {
      const { data } = await getSpecialties();
      setSpecialties(data);
    })();
  }, []);

  return (
    <RowBoot
      className={`${css['background-search']} ${
        searching ? css.searching : ''
      }`}
    >
      <ColBoot lg={12} md={12} sm={12}>
        <Formik
          onSubmit={(values) => {
            const searchParams = new URLSearchParams(values);
            Router.push(`/buscar/${searchParams}`);
          }}
          initialValues={{
            autocomplete: autocompleteValue,
            insurance: insuranceValue,
            city: cityValue,
            accepts_in_person: acceptsInPersonValue,
            accepts_telemedicine: acceptsTelemedicineValue,
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            values: {
              autocomplete,
              city,
              accepts_in_person,
              accepts_telemedicine,
            },
            errors,
          }) => (
            <FormBoot noValidate>
              {describe && (
                <RowBoot className={css.center}>
                  <ColBoot lg={10} md={10} sm={10}>
                    <h1 className={css.title}>{titleSearch}</h1>
                  </ColBoot>
                </RowBoot>
              )}
              <FormBoot.Row className={css.center}>
                <FormBoot.Group
                  controlId="autocomplete"
                  className={css['group-custom']}
                  as={ColBoot}
                  lg={2}
                  md={10}
                  sm={10}
                >
                  <div className={css['input-custom-content']}>
                    <FormBoot.Label
                      className={cx({ [css.show]: autocomplete?.length })}
                    >
                      Nome ou especialidade
                    </FormBoot.Label>
                    <Autocomplete
                      items={specialties}
                      value={specialty}
                      getItemValue={({ term }) => term}
                      shouldItemRender={shouldItemRenderSpecialties}
                      onChange={({ target: { value } }) => {
                        setSpecialty(value);
                        setFieldValue('autocomplete', value);
                      }}
                      onSelect={(value) => {
                        setSpecialty(value);
                        setFieldValue('autocomplete', value);
                      }}
                      renderMenu={(items) => (
                        <ListGroupBoot
                          as="ul"
                          className={css.autocomplete}
                          // eslint-disable-next-line react/no-children-prop
                          children={items}
                        />
                      )}
                      renderItem={(item, isHighlighted) => (
                        <ListGroupBoot.Item
                          key={item.term}
                          as="li"
                          active={isHighlighted}
                        >
                          {item.term}
                        </ListGroupBoot.Item>
                      )}
                      renderInput={(props) => (
                        <FormBoot.Control
                          type="autocomplete"
                          name="autocomplete"
                          placeholder="Nome ou especialidade"
                          className={cx({
                            [css.custom]: autocomplete?.length,
                          })}
                          isInvalid={
                            !!errors.autocomplete && touched.autocomplete
                          }
                          {...props}
                        />
                      )}
                    />
                  </div>
                </FormBoot.Group>
                <FormBoot.Group
                  controlId="city"
                  className={css['group-custom']}
                  as={ColBoot}
                  lg={2}
                  md={10}
                  sm={10}
                >
                  <div className={css['input-custom-content']}>
                    <FormBoot.Label
                      className={cx({ [css.show]: city?.length })}
                    >
                      Cidade
                    </FormBoot.Label>
                    <FormBoot.Control
                      type="city"
                      name="city"
                      placeholder="Cidade"
                      value={city}
                      onChange={handleChange}
                      className={city?.length ? css.custom : ''}
                      isInvalid={!!errors.city && touched.city}
                    />
                    <FormBoot.Label
                      onClick={() => setFieldValue('city', userLocation)}
                      className={`${!city?.length ? css.show : ''} ${
                        css['label-right']
                      }`}
                    >
                      Minha localização
                      <img alt="localização" src={imagePaths.location} />
                    </FormBoot.Label>
                  </div>
                </FormBoot.Group>
                <FormBoot.Group
                  controlId="insurance"
                  className={css['group-custom']}
                  as={ColBoot}
                  lg={2}
                  md={10}
                  sm={10}
                >
                  <div className={css['input-custom-content']}>
                    <FormBoot.Label
                      className={cx({ [css.show]: insurance?.length })}
                    >
                      Convênio (Todos)
                    </FormBoot.Label>
                    <Autocomplete
                      items={insurances}
                      value={insurance}
                      getItemValue={(value) => value}
                      shouldItemRender={shouldItemRenderInsurances}
                      onChange={({ target: { value } }) => {
                        setInsurance(value);
                        setFieldValue('insurance', value);
                      }}
                      onSelect={(value) => {
                        setInsurance(value);
                        setFieldValue('insurance', value);
                      }}
                      renderMenu={(items) => (
                        <ListGroupBoot
                          as="ul"
                          className={css.autocomplete}
                          // eslint-disable-next-line react/no-children-prop
                          children={items}
                        />
                      )}
                      renderItem={(item, isHighlighted) => (
                        <ListGroupBoot.Item
                          key={item}
                          as="li"
                          active={isHighlighted}
                        >
                          {item}
                        </ListGroupBoot.Item>
                      )}
                      renderInput={(props) => (
                        <FormBoot.Control
                          placeholder="Convênio (Todos)"
                          name="insurance"
                          type="insurance"
                          value={insurance}
                          onChange={handleChange}
                          className={cx({ [css.custom]: insurance?.length })}
                          isInvalid={!!errors.insurance && touched.insurance}
                          {...props}
                        />
                      )}
                    />
                  </div>
                </FormBoot.Group>
                <ColBoot
                  lg={2}
                  md={10}
                  sm={10}
                  className={css['button-switch-content']}
                >
                  <ButtonGroupBoot toggle className="mb-2">
                    <ToggleButtonBoot
                      className={cx({ [css.active]: accepts_in_person })}
                      type="radio"
                      name="accepts_in_person"
                      value={accepts_in_person}
                      checked={accepts_in_person}
                      onChange={() => {
                        setFieldValue('accepts_in_person', true);
                        setFieldValue('accepts_telemedicine', false);
                      }}
                    >
                      Presencial
                    </ToggleButtonBoot>
                    <ToggleButtonBoot
                      className={cx({ [css.active]: accepts_telemedicine })}
                      type="radio"
                      name="accepts_telemedicine"
                      value={accepts_telemedicine}
                      checked={accepts_telemedicine}
                      onChange={() => {
                        setFieldValue('accepts_telemedicine', true);
                        setFieldValue('accepts_in_person', false);
                      }}
                    >
                      Teleconsulta
                    </ToggleButtonBoot>
                  </ButtonGroupBoot>
                  <TelemedicineModal>
                    <p className={css.help}>
                      Como funciona
                      <img alt="Como funciona?" src={imagePaths.help} />
                    </p>
                  </TelemedicineModal>
                </ColBoot>
                <ColBoot
                  lg={1}
                  md={10}
                  sm={10}
                  className={css['search-content']}
                >
                  <button
                    onClick={handleSubmit}
                    className={css.search}
                    type="submit"
                  >
                    <span>Buscar horários</span>
                    <img
                      className={css.action}
                      alt="Pesquisar"
                      src={imagePaths.search}
                    />
                  </button>
                </ColBoot>
              </FormBoot.Row>
            </FormBoot>
          )}
        </Formik>
      </ColBoot>
    </RowBoot>
  );
};

Search.defaultProps = {
  autocompleteValue: '',
  insuranceValue: '',
  userLocation: '',
  cityValue: '',
  acceptsInPersonValue: true,
  acceptsTelemedicineValue: false,
};

export default Search;
